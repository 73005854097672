<template>
  <div>
    <search-system-product @submit="submitSearch" />

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >
      <template slot="name" slot-scope="name,record">
        <a @click="showDeatilModal(record)">{{ name }}</a>
      </template>
      <template slot="images" slot-scope="imgs, record">
        <!--        <span v-if="imgs && imgs.length > 0 " class="custom-blue" @click="showImg(record)">{{ imgs[0].response.filename }}</span>-->
        <img v-if="imgs && imgs.length > 0" :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
      <div slot="effective" slot-scope="effective">
        <span>{{ effective ? '有效': '失效' }}</span>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />
    <!-- 产品模态框 -->
    <show-product
      v-if="isShowModal"
      :visible.sync="isShowModal"
      :record="detailRecord"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchSystemProduct from '@/views/tenants/products/Search'
import PreviewImage from '@/components/PreviewImage'
import { findSystemProducts } from '@/api/product'

export default {
  name: 'SystemProductList',
  components: {
    Pagination,
    PreviewImage,
    SearchSystemProduct,
    ShowProduct: () => import('@/views/tenants/products/Show')
  },
  data() {
    return {
      query: {},
      detailRecord: {},
      data: [],
      loading: true,
      previewVisible: false,
      previewImages: [],
      isShowModal: false,
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  computed: {
    tenantId() {
      return parseInt(this.$route.params.id)
    },
    columns() {
      return [
        {
          title: '产品名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '型号',
          dataIndex: 'product_model'
        },
        {
          title: '类型',
          width: 90,
          ellipsis: true,
          dataIndex: 'product_type'
        },
        {
          title: '价格',
          dataIndex: 'str_benefit_the_people'
        },
        {
          title: '用于订单其他项目',
          width: 200,
          ellipsis: true,
          dataIndex: 'str_sales_scope'
        },
        {
          title: '产生任务',
          ellipsis: true,
          dataIndex: 'str_tasks'
        },
        {
          title: '图片',
          dataIndex: 'images',
          ellipsis: true,
          scopedSlots: { customRender: 'images' }
        },
        {
          title: '产品介绍',
          ellipsis: true,
          dataIndex: 'remark'
        },
        {
          title: '状态',
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          width: 150,
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        }
      ]
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },
    showDeatilModal(record) {
      this.detailRecord = record
      this.isShowModal = true
    },
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findSystemProducts(Object.assign({ tenant_id: this.tenantId }, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
